import React, {useEffect, useState} from 'react';
import axiosClient from "../../src/axiosClient";
import styles from './SurveyBanner.module.scss';
import SweetAlert2 from "react-sweetalert2";
import Lottie from "lottie-react";
import Animation from "./Animation.json";

const SurveyBanner = ({isAuthorized}) => {
  const [survey, setSurvey] = useState(null);
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const loadSurveys = () => {
    axiosClient.get('/affiliate/surveys/actual_surveys').then(response => {
      if (response.data.surveys.length > 0) {
        setSurvey(response.data.surveys[0])
      }
    })
  }

  const goToSurvey = (event, force=false) => {
    if (!force && !isAuthorized) {
      return setAlertIsOpen(!alertIsOpen);
    }
    axiosClient.get(`/affiliate/surveys/${survey.id}/open`).then(response => {
      if (response.data.path) {
        window.open(response.data.path, '_blank');
      }
    })
  }

  useEffect(() => {
    loadSurveys();
  }, []);

  if (!survey) {
    return null
  }

  return (
    <div className={`container main-cont-width ${styles.wrapper}`}>
      <div className={`alert alert-success mt-2 cursor-pointer ${styles.alert}`} role="alert" onClick={goToSurvey}>
        <strong>{survey.marketing_title}</strong>
        <p className="mb-0 col-11">{survey.marketing_description}</p>
        <div className={styles.lottieContainer}>
          <Lottie animationData={Animation} className={styles.animation}/>
        </div>
      </div>
      <SweetAlert2
        title="Alert"
        show={alertIsOpen}
        denyButtonText='SIGN UP'
        confirmButtonText='CONTINUE'
        showConfirmButton
        showDenyButton
        onResolve={(e) => {
          if (e.isConfirmed) {
            return goToSurvey(e, true)
          }
          Turbolinks.visit('/users/sign_up')
        }}
        denyButtonColor='#FF8F51'
        confirmButtonColor='#FF8F51'
      >
        <strong className='fs-20i'>We recommend registering.</strong>
        <p className="fs-12 text-muted mt-2">Unregistered users are not eligible for bonus votes.</p>
      </SweetAlert2>
    </div>
  );
}

export default SurveyBanner;