import React, { useEffect, useState } from 'react';

const formatTime = (seconds) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return {
    hrs: String(hrs).padStart(2, '0'),
    mins: String(mins).padStart(2, '0'),
    secs: String(secs).padStart(2, '0'),
  };
};

const ContestEndingTimer = ({ contestEndTime, orderEndTime }) => {
  const [timeLeft, setTimeLeft] = useState({ hrs: '00', mins: '00', secs: '00' });
  const [timerEnded, setTimerEnded] = useState(false);
  const [hideTimer, setHideTimer] = useState(false);

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const end = new Date(contestEndTime).getTime();
      const orderEnd = orderEndTime ? new Date(orderEndTime).getTime() : null;
      let distance = end - now;

      const hoursLeft = distance / (1000 * 3600);
      if (hoursLeft > 36) {
        setHideTimer(true);
        return;
      }

      if (distance <= 0) {
        setTimerEnded(true);
        distance = orderEnd ? orderEnd - now : 0;
      }

      if (distance < 0 && orderEnd) {
        distance = orderEnd - now;
      }

      setTimeLeft(formatTime(Math.max(distance, 0) / 1000));
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, [contestEndTime, orderEndTime]);

  if (timerEnded || hideTimer) {
    return null;
  }

  return (
    <div className='text-danger' style={{marginBottom:'15px', }}>
      Ends in {timeLeft.hrs}:{timeLeft.mins}:{timeLeft.secs} 🕚
    </div>
  );
};

export default ContestEndingTimer;
