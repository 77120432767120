import axios from "axios";

export const deleteCard = async (fingerprint) => {
  try {
    const response = await axios.delete(`/api/users/destroy_card.json`, {
      data: { payment_method_id: fingerprint }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};