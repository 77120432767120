import React, {useState} from 'react';
import cn from "classnames";
import style from './SavedCards.module.scss'
import {payWithPaymentMethod} from "../../PayPal/actions";
import {deleteCard} from '../actions'
import ConfirmDeleteModal from '../modal/index'
import visa  from  '../../../../assets/images/shop/visa.svg'
import american_express  from  '../../../../assets/images/shop/american_express.svg'
import mastercard  from  '../../../../assets/images/shop/mastercard.svg'
import another  from  '../../../../assets/images/shop/another.svg'

export default function StripeSavedCards(props) {
    const { orderId, participantId, stripePaymentMethods, currency, cents, showStripeCheckout, handleCardSelection, setShowStripeCheckout} = props
    const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cents/100);
    const [selectedCardId, setSelectedCardId] = useState( null);
    const [isLoading, setIsLoading] = useState(false);
    const [deletedCardIds, setDeletedCardIds] = useState([])
    const [isDeleting, setIsDeleting] = useState(false);
    const [cardToDelete, setCardToDelete] = useState(null);

    const CardBrandIcon = ({ brand }) => {
      const getIcon = (brand) => {
        switch (brand.toLowerCase()) {
          case 'visa':
            return visa;
          case 'mastercard':
            return mastercard;
          case 'amex':
            return american_express;
          default:
            return another;
        }
      };

      return <img src={getIcon(brand)} alt={`${brand} Icon`} style={{ width: '30px', marginLeft: '30px' }} />;
    };

    const pay = () => {
      setIsLoading(true)
      payWithPaymentMethod(orderId, selectedCardId, participantId).finally(() => setIsLoading(false))
    }

    const confirmDeleteCard = (fingerprint) => {
      setCardToDelete(fingerprint);
      setIsDeleting(true);
    };

    if (stripePaymentMethods.length === 0 || stripePaymentMethods.every(card => deletedCardIds.includes(card.fingerprint))) {
      return null;
    }

    const DestroyCard = async (fingerprint) => {
      if (fingerprint && cardToDelete ) {
        await deleteCard(fingerprint);
        setDeletedCardIds([...deletedCardIds, fingerprint]);

        if (selectedCardId === fingerprint) {
          const remainingCards = stripePaymentMethods.filter(card => !deletedCardIds.includes(card.fingerprint) && card.fingerprint !== cardId);
          setSelectedCardId(remainingCards[0]?.id || null)
        }
      }
      setIsDeleting(false);
      setCardToDelete(null);
      setShowStripeCheckout(true)
    }

    const handleCardClick = (cardId) => {
      if (selectedCardId === cardId) {
        setSelectedCardId(null);
        handleCardSelection(null);
      } else {
        setSelectedCardId(cardId);
        handleCardSelection(cardId);
      }
    };

    return(
        <div>
            <div className={style.cardsContainer}>
                {stripePaymentMethods.filter(card => !deletedCardIds.includes(card.fingerprint)).
                  map((card, index) => (
                    <div className={cn(style.bankCard, 'd-flex   flex-column align-items-center ', {
                      [style.checked]: selectedCardId === card.id,
                    })}
                         onClick={() => handleCardClick(card.id)}
                         key={index}
                    >
                      <div className={cn(style.bankCard, 'd-flex align-items-center justify-content-between ')}>
                        <div >
                          <CardBrandIcon brand={card.brand} />
                        </div>
                        <div style={{flexGrow: 1, marginTop: '10px'}}>
                          <span className="last-vote-user-name">•••• •••• •••• {card.last4}</span>
                            <p className="last-vote-baby-name">Exp: {card.exp_month}/{card.exp_year}</p>
                          </div>
                          <div style={{paddingRight: '20px'}}>
                            <input
                              className={style.checkedInput}
                              type="checkbox"
                              name="selectedCard"
                              checked={selectedCardId === card.id}
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                        </div>
                        {!showStripeCheckout &&
                          <button
                            onClick={() => confirmDeleteCard(card.fingerprint)}
                            className={style.buttonDelete}
                          >
                            Delete this card
                          </button>
                        }
                    </div>
                ))}
            </div>
          {
            !showStripeCheckout && (
              <button
                onClick={pay}
                disabled={!selectedCardId || isLoading}
                        className="btn btn-primary w-100 h-40i br-8i pt-12i button-text mt-8"
                        style={{fontSize: '16px'}}
                    >
                      {isLoading && <i className="fa fa-spinner fa-spin"> </i>}
                      Pay {formattedPrice}
                    </button>
                )
          }
          {showStripeCheckout &&
            <div className={style.dividerWithText}>
              <span className={style.dividerText}>or use another payment method</span>
            </div>
          }
          <ConfirmDeleteModal
            isOpen={isDeleting}
            onConfirm={DestroyCard}
            onCancel={() => setIsDeleting(false)}
            fingerprint={cardToDelete}
          />
        </div>

    )
}