import React, {useMemo, useState} from 'react'
import SweetAlert2 from "react-sweetalert2";
import BuyButtonContent from "./content";
import style from './BuyButton.module.scss'
import BuyButtonShortContent from "./shortContent";

const BuyProductButton = ({product, discount, original_price, price, participant_id, is_fair_limit, contest_end_at, order_end_time}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const buttonTitle = useMemo(() => {if (discount.cents > 0) {
      return(
        <>
          <s>{original_price}</s>
          &nbsp;
          {price}
        </>
      )
    }
    return price
  }, [discount, original_price, price])

  return (
    <>
      <button
        className='btn btn-outline-primary pb-2 pt-2 ps-3 pe-3 vote-button'
        disabled={is_fair_limit}
        onClick={() => setModalIsOpen(true)}
      >
        {buttonTitle}
      </button>
        { !is_fair_limit && <SweetAlert2
            backdrop="rgba(0, 0, 0, 0.87)"
            show={modalIsOpen}
            onResolve={() => setModalIsOpen(false)}
            showConfirmButton={false}
        >
            {
                modalIsOpen && (
                    <div className={style.contentContainer}>
                        {
                            process.env.STRIPE_PUBLIC_KEY ? (
                                <BuyButtonContent product={product} participantId={participant_id}
                                                  contest_end_at={contest_end_at} order_end_time={order_end_time}
                                />
                            ) : (
                                <BuyButtonShortContent product={product} participantId={participant_id}
                                                       contest_end_at={contest_end_at} order_end_time={order_end_time}
                                />
                            )
                        }
                        <i className={`fa fa-close ${style.closeIcon}`} onClick={() => setModalIsOpen(false)}></i>
                    </div>
                )
            }
        </SweetAlert2>}
    </>
  )
}

export default BuyProductButton