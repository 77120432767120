import React, {useEffect, useState} from 'react'
import axios from "axios";
import PayPal from "../../PayPal";
import style from '../BuyButton.module.scss'
import ContestEndingTimer from "../content/timer";

const fetcher = (url, params) => axios.get(url, {params}).then(res => res.data)

export default function BuyButtonShortContent({product, participantId, contest_end_at, order_end_time}) {
  const createOrdersPath = `/api/products/${product.id}/create_orders.json`
  const [data, setData] = useState(null)

  useEffect(() => {
    axios.post(createOrdersPath, { participant_id: participantId }).then(({data}) => setData(data))
  }, [])

  if (!data) return <p>Loading...</p>

  return (
    <>
        {
            data.baby && (
                <div
                    className={style.avatar}
                    style={{
                        width: '100px',
                        height: '100px',
                        backgroundImage: `url(${data.baby.avatar})`,
                        backgroundSize: 'cover',
                        margin: '10px auto'
                    }}
                ></div>
            )
        }
      <h5 className={style.productTitle}>{product.title}</h5>

      <ContestEndingTimer
        contestEndTime={contest_end_at}
        orderEndTime={order_end_time}
      />

      {data.baby && <p className={style.babyName}>{data.baby.name}</p>}
      <PayPal
        orderId={data.paypal.id}
        cents={data.paypal.price_cents}
        currency={data.paypal.price_currency}
        participantId={data.paypal.participant_id}
        fetcher={fetcher}
      />
    </>
  )
}