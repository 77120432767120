import { post } from '../../src/util'

export const successRedirectPath = (orderId, participantId) =>
    participantId ? `/shop/completed?order_id=${orderId}&participant_id=${participantId}` : `/products/complete?order_id=${orderId}`

const successRedirect = (orderId, participantId) => {
  Application.Classes.Toastr.success('Payment was successful!')
  // Show a success message or redirect
  return window.location = successRedirectPath(orderId, participantId)
}

export const failedRedirect = (orderId) => {
  return window.location = (`/orders/${orderId}/failed`)
}

export const createOrder = async (orderId) => {
  try {
    const { data: { id, error } } = await post(`/api/orders/${orderId}/paypal_create.json`)
    if (error) failedRedirect(orderId)

    return id
  } catch (err) {
    failedRedirect(orderId)
  }
}

export const payWithPaymentMethod = async (orderId, pmId, participantId) => {
  try {
    await post(`/api/orders/${orderId}/stripe_pay_saved_card.json`, { payment_method_id: pmId })
    successRedirect(orderId, participantId)
  } catch (err) {
    failedRedirect(orderId)
  }
}

export const onApprove = async (orderId, participantId) => {
  try {
    await post(`/api/orders/${orderId}/paypal_capture.json`)
    successRedirect(orderId, participantId)
  } catch (err) {
    failedRedirect(orderId)
  }
}

export const showError = (errObj) => {
  var msg = "Sorry, your transaction could not be processed.";
  if (errObj?.response?.data?.error) {
    msg = [msg, errObj?.response?.data?.error].join(' ')
  }

  Application.Classes.Toastr.error(msg)

  if (errObj?.response?.data?.new_order_id) {
    window.location = `/orders/${errObj?.response?.data?.new_order_id}`
  }
}

