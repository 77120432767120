import React from 'react';
import style from './Modal.module.scss';

export default function ConfirmDeleteModal ({ isOpen, onConfirm, onCancel, fingerprint })  {
  if (!isOpen) return null;

  return (
    <div className={style.modalBackdrop}>
      <div className={style.modal}>
        <p>Are you sure you want to delete the card?</p>
        <button onClick={() => onConfirm(fingerprint)} className="btn btn-primary w-100 mr-6 h-40i br-8i pt-12i button-text mt-8">Yes</button>
        <button onClick={() => onCancel(false)} className="btn btn-primary w-100 h-40i br-8i pt-12i button-text mt-8">No</button>
      </div>
    </div>
  );
};

